.home{
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro{
    max-width: 540px;
    text-align: center;
    z-index: 2;
}

.home__img{
    margin-bottom: 1,5rem;
    z-index: 2;
}

.home__name{
    font-size: var(--h1-font-size);
    font-weight: var(--font-bold);
    margin-bottom: 0.5rem;
}

.home__socials{
    display: flex;
    column-gap: 1.75rem;
    margin: 1.5rem 0;
    justify-content: center; 
    z-index: 2;
}

.home__social-link{
    color: var(--title-color);
    font-size: 1.3rem;
    transition: .3s;
    z-index: 2;
}

.home__social-link:hover{
    color: rgb(5, 146, 174);
}

.scroll__down{
    position: absolute;
    bottom: 2.5rem;
    left: 0;
    width: 100%;
    z-index: 2;
}

.home__scroll-name{
    font-size: var(--small-font-size);
    z-index: 2;
}

.mouse{
    border: 2px solid #454360;
    display: block;
    height: 1.6rem;
    width: 1.25rem;
    margin: auto;
    margin-top: .75rem;
    border-radius: 1rem;
    position: relative;
    z-index: 2;
}

@keyframes ani-mouse{
    0%{
        top: 29%;
    } 
    15%{
        top: 50%;
    }
    50%{
        top: 50%;
    }
    100%{
        top: 29%;
    }
}

.wheel{
    background-color: var(--title-color);
    border-radius: 100%;
    width: 0.25rem;
    height: 0.25rem;
    position: absolute;
    top: .5rem;
    left: 50%;
    transform: translateX(-50%);
    animation: ani-mouse 2s infinite;
}

.shapes{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}

.shape {
    position: absolute;
}

.s1{
    left: 2%;
    top: 10%;
}
.s2{
    left: 18%;
    top: 30%;
}

.s3{
    left: 5%;
    bottom: 30%;
}

.s4{
    left: 2%;
    bottom: 10%;
}

.s5{
    left: 44%;
    top: 10%;
}

.s6{
    left: 36%;
    bottom: 10%;
}

.s7{
    top: 20%;
    right: 25%;
}

.s8{
    bottom: 20%;
    right: 24%;
}

.s9{
    top: 10%;
    right: 2%;
}

.s10{
    top: 45%;
    right: 11%;
}

.s11{
    bottom: 10%;
    right: 2%;
}

/* home.css */
#hireMeForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  #hireMeFormName, #hireMeFormEmail {
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    background-color: #f0f0f0;
    width: 100%;
    max-width: 300px;
    font-size: 1rem;
  }
  
  #hireMeFormButton {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    background-color: #454360;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
  }
  
  #hireMeFormButton:hover {
    background-color: #353450;
  }