.work__filters{
    display: flex;
    align-items: center;
    column-gap: 1.875rem;
    margin-bottom: 2.5rem;
    padding-left: 15%;

}

.work__item{
    cursor: pointer;
    font-weight: var(--font-bold);
    transition: .3s;
}

.work__item:hover{
    color: var(--first-color);
}

.work__container{
    padding-left: 15%;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.875rem;
}

.work__card{
    position: relative;
    overflow: hidden;
    border-radius:var(--border-radius); 
    box-shadow: var(--shadow);
}

.work__mask{
    background: #6c6ce5;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transition: .3s;
    opacity: 0;
}

.work__card:hover .work__mask{
    opacity: 0.8;
}

.work__category{
    color: #fff;
    background-color: var(--first-color);
    border-bottom-left-radius: 0.98rem;
    border-top-right-radius: 0.98rem;
    position: absolute;
    top: 0;
    left: 1.5rem;
    font-size: var(--small-font-size);
    display: inline-block;
    padding: 0.19rem 0.625rem;
    transform: translateY(-40px);
    transition: .3s;
}

.work__title{
    color: #fff;
    font-size: var(--h3-font-size);
    margin: 0 0 0.98rem;
    padding: 0 1.25rem;
    position: absolute;
    top: 3.75rem;
    transform: translateY(30px);
    transition: .3s;
    opacity: 0;
}

.work__button{
    color: #fff;
    position: absolute;
    bottom: 1.5rem;
    font-size: var(--h3-font-size);
    display: block;
    background-color: #ffd15c;
    height: 40px;
    width: 40px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    transition: .3s;
    opacity: 0;
}

.work__card:hover .work__button{
    opacity: 1;
}

.work__card:hover .work__title, .work__card:hover .work__category{
    transform: translateY(0);
    opacity: 1;
}


@media screen and (max-width :650px) {
    .work__filters{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        padding-left: 5%;
        column-gap: 0.6rem;
    }

    .work__container {
        padding-left: 5%;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.875rem;
    }

    .work__button {
        color: #fff;
        position: absolute;
        bottom: 0.5rem;
        font-size: 12px;
        display: block;
        background-color: #ffd15c;
        height: 20px;
        width: 20px;
        cursor: pointer;
        border-radius: 50%;
        text-align: center;
        line-height: 0px;
        transition: .3s;
        opacity: 0;
    }
    .work__title {
        color: #fff;
        font-size: 10px;
        margin: 0 0 0.98rem;
        padding: 0 1.25rem;
        position: absolute;
        top: 3.75rem;
        transform: translateY(30px);
        transition: .3s;
        opacity: 0;}

        .work__category {
            color: #fff;
            background-color: var(--first-color);
            border-bottom-left-radius: 0.98rem;
            border-top-right-radius: 0.98rem;
            position: absolute;
            top: 0;
            left: 1.5rem;
            font-size: 10px;
            display: inline-block;
            padding: 0.19rem 0.625rem;
            transform: translateY(-40px);
            transition: .3s;}
}