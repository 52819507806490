.main{
  margin-left: 110px;
}

@media screen and (max-width: 1024px){

  .main{
    margin-left: 0;
  }
  
}